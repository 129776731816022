<template>
    <div v-show="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="grid grid-cols-1">
                        <div>
                            <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Failed payments</h3>
                            <div class="mt-3">
                                <p class="text-sm mt-2 text-gray-100 font-medium">
                                    Add a new payment card and clear all outstanding balances on your failed payments in order to proceed.
                                </p> 
                            </div>

                            <div v-for="(venue, index) in failed_payments" :key="venue.id">

                                <hr class="mt-6">
                                <div v-for="(log) in venue" :key="log.id" class="my-6">
                                    <p class="uppercase text-gp_pink-default-500 font-bold">{{ log.venue_name }}</p>
                                    <p v-show="log.is_payg || log.is_monthly_payg_payment" class="text-sm text-white">Type: PAYG</p>
                                    <p v-show="log.is_monthly_subscription_payment" class="text-sm text-white">Type: MONTHLY SUBSCRIPTION</p>
                                    <p class="text-sm text-white">Payment failed: {{ formattedDate(log.updated_at) }}</p>
                                </div>

                                <div class="w-full py-3">
                                    <GPButton @click.native="updateVenuePaymentCard(index)"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Update venue payment card</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </transition>        
        </div>
        <SaveCard ref="savecardmodal" @clearPayments="clearFailedPayments($event)" />
    </div>
</template>

<script>

import { paymentsMethods, apiMethods } from "@/state/helpers";
import GPButton from '../../../../components/ui/buttons/Button.vue';
import { ArrowNarrowRightIcon } from "@vue-hero-icons/outline"
import SaveCard from '../../../../components/modal/venues/subscriptions/SaveCard.vue'
import NProgress from 'nprogress';

export default
{
    name: "FailedPaymentsRyft", 
    data() {
        return {
            failed_payments: [],
            selectedVenue: null,
            modal_open: false,
        }
    },
    async mounted() {

        await this.getFailedPaymentsLogs()
            .then((response) => {
                this.failed_payments =  Object.values(response.data).reduce((acc, log) => {
                    const { venue_id } = log;
                    if (!acc[venue_id]) {
                        acc[venue_id] = [];
                    }
                    acc[venue_id].push(log);
                    return acc;
                }, {});

                if(response.data.length > 0) {
                    this.modal_open = true;
                }

            });
           
    },
    components: {
        GPButton,
        ArrowNarrowRightIcon,
        SaveCard
    },
    computed: {

    },
    watch: {

    },
    methods: {
        ...paymentsMethods, 
        ...apiMethods,
        formattedDate(failed_date) {
            const date = new Date(failed_date.replace(" ", "T"));
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const day = String(date.getDate()).padStart(2, '0');
            
            return `${year}-${month}-${day}`;
        },
        updateVenuePaymentCard(venue_id) {
            this.selectedVenue = parseInt(venue_id);
            this.$refs.savecardmodal.addVenueInfo({ id: venue_id , name: this.failed_payments[venue_id][0].venue_name, currency_id: this.failed_payments[venue_id][0].venue_currency, close_modal: true });
        },
        clearFailedPayments($event) {

            NProgress.start();
            this.$notify("Attempting to clear failed payments.");

            const data = Object.keys(this.failed_payments)
                            .filter(key => key !== String(this.selectedVenue))  
                            .reduce((acc, key) => {
                                acc[key] = this.failed_payments[key];  
                                return acc;
                            }, {});

            this.failed_payments = data;

            const params = {
                venue_id: this.selectedVenue,
                card_id: $event
            }

            this.clearVenueFailedPayments(params)
                .finally(() => {
                    if (Object.keys(this.failed_payments).length === 0) {
                        this.modal_open = false;
                    }
                })
            
        }
    }
}

</script>