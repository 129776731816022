<template>
    <div v-show="!acc_info.has_active_legacy_subscription && !acc_info.has_payg_monthly_venue && !acc_info.has_active_venue_subscription && !acc_info.has_active_free_trial && !acc_info.has_active_tokens" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-6 px-6 md:px-12 justify-items-center text-center">
                            <h3 class="block font-bold text-gp_pink-default-500 uppercase mt-6" id="modal-title">Feature Locked</h3> 
                            <p class="text-gray-100 font-bold">
                                Click here to top-up your venue with credits to enable the full GigPig experience.
                            </p>

                            <div class="flex flex-wrap gap-2 justify-center items-center">
                                <div class="w-full">
                                    <TRichSelect
                                        id="venues"
                                        name="venues"
                                        v-model="venueSelected"
                                        :options="venues"
                                        value-attribute="id"
                                        text-attribute="name"
                                        class="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
                                    />
                                </div>
                                <div @click="topUpVenue()" class="bg-gp_pink-default-500 hover:bg-gp_pink-default-400 cursor-pointer my-2 md:my-6 py-2 px-6 rounded-lg flex space-x-2">
                                    <DatabaseIcon stroke="white"/>
                                    <span class="text-white">Add credits now</span>
                                </div>
                            </div>

                        </div>
                    </div>

                   
                </div> 
            </transition>    
            <TopUpOptions ref="topupmodal" /> 
        </div>
    </div>
</template>

<script>
import { DatabaseIcon } from "@vue-hero-icons/outline"
import { paymentsMethods } from "@/state/helpers";
import { TRichSelect } from 'vue-tailwind/dist/components'
import TopUpOptions from '../../components/modal/venues/subscriptions/TopUpOptions.vue'
import { mapActions, mapGetters } from "vuex";

export default
{
    name: "FeatureLocked", 
    data() {
        return {
            acc_info: null,
            venues: [],
            venueSelected: null
        }
    },
    components: {
        DatabaseIcon, TRichSelect, TopUpOptions
    },
    async mounted() {
        await this.getUserInfo().then(response => this.acc_info = response)
        this.getRyftVenueList().then(response => this.venues = response)
    },
    computed:  {
        ...mapGetters('account', ['getAccInfo']),
    },
    watch: {
		getAccInfo: {
			immediate: true,
			handler(newValue) {
				this.acc_info = newValue;
			}
		}
	},
    methods: {
        ...paymentsMethods,
        ...mapActions('account', ['getUserInfo']),
        topUpVenue() {
            
            if (!this.venueSelected) {
                this.$notify("Please select a venue to credit.");
                return;
            }

            let target = this.venues.find((f) => f.id === this.venueSelected);
            let payment_type = target.payg_charged_and_billed_monthly ? 'payg_charged_and_billed_monthly' : 'payg';

            this.$refs.topupmodal.openWithVenueInfo({ id: target.id , name: target.name, currency_id: target.currency_id, payment_type: payment_type });
        }
    }
}

</script>