<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="grid grid-cols-1">
                        <div class="h-auto w-full">
                            <div class="flex justify-between">
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">NO TOKENS AVAILABLE</h3>
                            </div>

                            <div class="mt-10">
                                <div>
                                    <p class="text-sm mt-2 text-gray-100 font-medium">
                                        Clicking the 'Purchase token' button below will charge your default payment card £10.00 to proceed with this gig.
                                    </p> 
                                </div>
                            </div>

                            <div class="w-full mt-10">
                                <GPButton @click.native="purchase()"
                                                :disabled="isSubmitting"
                                                :loading="isSubmitting"
                                                color="pink"
                                                class="w-full justify-between"
                                >
                                    <span class="font-semibold">Purchase token</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </GPButton> 
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
    </div>
</template>

<script>
import GPButton from '../../../../components/ui/buttons/Button.vue';
import { ArrowNarrowRightIcon } from "@vue-hero-icons/outline"
import {paymentsMethods} from "@/state/helpers";

export default
{
    name: "QuickTopUpForGig", 
    data() {
        return {
            modal_open: false,
            isSubmitting: false,
            payload: {
                gig_id: null,
                card_id: null,
                cost_per_token: '12.00'
            }
        }
    },
    components: {
        GPButton,
        ArrowNarrowRightIcon
    },
    methods: {
        ...paymentsMethods,
        toggleModal() {
            this.modal_open = !this.modal_open;
        },
        purchase() {

            this.isSubmitting = true;
          
            this.addSinglePaygPaymentForGig(this.payload)
                .then(() => {
                    this.$notify("Payment successful.");
                    this.$emit('resendGig');
                    this.isSubmitting = false;
                })
                .catch(() => {
                    this.$notify("Payment failed. Please contact support for further assistance.");
                })

        },
        openWithInfo(info) {

            this.payload.gig_id = info.gig_id;

            this.getVenueDefaultPaymentCard({ venue_id: info.venue_id })
                .then((response) => {
                    this.payload.card_id = response.data.id;
                    this.toggleModal();
                });
        }
    }
}

</script>