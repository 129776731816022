<template>

	<div>
		<InlineNotification Heading="Adding a new venue" class="ml-4" v-show="!this.user.is_opus">
			<template slot="body">
				<p v-show="acc_info && !acc_info.has_active_free_trial">Click the “Add new venue” button and fill in the details to register your venue. Repeat this process if you have more than one site. All your venues will be managed under this account.</p>
				<div v-show="acc_info && acc_info.has_active_free_trial" class="space-y-3">
					<p>Click the ‘Add new venue’ button and fill in the details to register additional venues.</p>
					<p>All your venues will be managed under this account. This feature is available when you post your first gig after your trial, or when you start a subscription.</p>
				</div>
			</template>
			<template slot="cta">
				<div @click="addVenue()" class="gp_cta cursor-pointer">Add new Venue</div>
			</template>
		</InlineNotification>

		<PageDetails>

			<!-- <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Your venues</h1>  -->

			<template v-if="isLoading">
				<div class="p-8 flex justify-center items-start">
					<PageSpinner 
						tailwind-diameter="8"
						tailwind-border="4"
						tailwind-color="pink-500"
					/>
				</div>
			</template>

			<template v-else-if="!isLoading && !hasData">
				<NoDataMsgWrapper>There are no venues found for this account</NoDataMsgWrapper>
			</template>

			<template v-else>
				<TableWrapper>
					<template #header-rows>
						<tr>
							<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6"></th>
							<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6"></th>
							<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">
								<span class="sr-only">Upcoming gigs</span>
							</th>
							<th scope="col" class="relative w-8 px-3 py-3.5 ">
								<span class="sr-only">Edit</span>
							</th>
						</tr>
					</template>
					<template #body-rows v-if="venues">
						<tr v-for="(venue, index) in venues" :key="venue.id">
							<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
								<div class="flex items-center gap-3">
									<img
										class="shadow self-start w-8 h-8 object-contain"
										:src="venueIcon(venue)"
										:alt="venue.name + ' logo'" 
									/>
									<span>{{ venue.name }}</span>
								</div>
								
								<dl class="font-normal lg:hidden">
									<dt class="sr-only sm:hidden">Upcoming gigs</dt>
									<dd class="mt-2 truncate text-gray-500 sm:hidden">
										<span>{{ getGigs(venue) }}</span>
									</dd>
								</dl>

								<div class="flex space-x-3 items-center lg:hidden mt-4">
									<div v-show="venue.active_tokens && venue.active_tokens.length" class="flex items-center space-x-3">
										<div>
											<ClockIcon stroke="white" class="w-6 h-6 bg-gp_pink-default-400 rounded-full p-1"/>
										</div>
										<div class="text-gray-500 text-sm font-light">
											<p v-show="venue.active_tokens.length">{{ venue.active_tokens.length }} credit<span v-show="venue.active_tokens.length !== 1">s</span> remaining</p>
											<p v-show="!venue.active_tokens.length">0 credits remaining</p>
											<div v-if="venue.active_subscription">
												<p v-show="venue.active_subscription && venue.active_subscription.id != 7 && venue.active_subscription.subscription_plan.id != 1">{{ calculateSubRenewalDate(index) }}</p>
												<p v-show="venue.active_subscription && venue.active_subscription.subscription_plan.id == 7">PAYG Venue</p>
											</div>
										</div>
									</div>
								</div>
								<div v-show="cardExpiryCheck(venue)" class="px-5 py-1 text-xs text-white bg-gray-600 rounded-md flex items-center space-x-3 uppercase font-bold lg:hidden mt-3 w-44">
									<CreditCardIcon stroke="white" class="w-5 h-6"/>
									<span>Expires Soon</span>
								</div>
								<div v-show="cardExpiredCheck(venue)" class="px-5 py-1 text-xs text-white bg-red-500 rounded-md flex items-center space-x-3 uppercase font-bold lg:hidden mt-3 w-44">
									<CreditCardIcon stroke="white" class="w-5 h-6"/>
									<span>Expired</span>
								</div>
							</td>
							<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
								<div v-show="venue.active_tokens && venue.payment_process == 'ryft'" class="col col-span-2 flex gap-12 items-center">
									<div class="flex space-x-3 items-center">
										<div>
											<ClockIcon stroke="white" class="w-6 h-6 bg-gp_pink-default-400 rounded-full p-1"/>
										</div>
										<div class="text-gray-500">
											<p v-show="venue.active_tokens.length">{{ venue.active_tokens.length }} credit<span v-show="venue.active_tokens.length !== 1">s</span> remaining</p>
											<p v-show="!venue.active_tokens.length">0 credits remaining</p>
											<div v-if="venue.active_subscription">
												<p v-show="venue.active_subscription && venue.active_subscription.id != 7 && venue.active_subscription.subscription_plan.id != 1">{{ calculateSubRenewalDate(index) }}</p>
												<p v-show="venue.active_subscription && venue.active_subscription.subscription_plan.id == 7">PAYG Venue</p>
											</div>
										</div>
									</div>
									<div v-show="cardExpiryCheck(venue)" class="px-5 py-1 text-xs text-white bg-gray-600 rounded-md flex items-center space-x-3 uppercase font-bold">
										<CreditCardIcon stroke="white" class="w-5 h-6"/>
										<span>Expires Soon</span>
									</div>
									<div v-show="cardExpiredCheck(venue)" class="px-5 py-1 text-xs text-white bg-red-600 rounded-md flex items-center space-x-3 uppercase font-bold">
										<CreditCardIcon stroke="white" class="w-5 h-6"/>
										<span>Expired</span>
									</div>
								</div>
							</td>
							<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
								<span>{{ getGigs(venue) }}</span>
							</td>
							<td class="w-8 px-3 py-4 text-right text-sm font-medium sm:pr-6">
								<a 
									@click.prevent="onEditVenue(venue)" 
									class="text-indigo-600 hover:text-indigo-900"
								>
									<span class="sm:hidden"><PencilAltIcon /></span>
									<span class="hidden sm:block">
										Edit<span class="sr-only">, {{ venue.name }}</span>
									</span>
								</a>
							</td>
						</tr>
					</template>
				</TableWrapper>
			</template>

		</PageDetails>
	</div>
</template>

<script>
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import TableWrapper from '../../components/ui/table/TableWrapper.vue';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import { PencilAltIcon, ClockIcon, CreditCardIcon } from "@vue-hero-icons/outline"
import pluralize from  '@/utils/pluralize.js'
import InlineNotification from '../../components/notifications/inline.vue'
import {venueMethods} from "@/state/helpers"; 
import { apiMethods, apiComputed } from '@/state/helpers'
import { mapActions } from 'vuex';

export default {
  components: {
		PageDetails,
		TableWrapper,
		PageSpinner,
		NoDataMsgWrapper,
		PencilAltIcon, ClockIcon, CreditCardIcon,
		InlineNotification
  },
  data() {
    return {
			venues:null,
			acc_info: null,
			isLoading:false,
    };
  },
  computed: {
		...apiComputed,
        user() {
            return this.$store.getters['user/userData']
        },
		hasData(){
			if(this.venues){
				if(this.venues.length){
					return true;
				}
			}
			return false;
		},
		getGigs(){
			return (venue) => pluralize(venue.gig_count, 'upcoming gig');
		},
		venueIcon(){
			return (venue) => {
				if(venue.icon && venue.icon.url){
					return venue.icon.url
				}else if(venue.icon && venue.icon.preview){
					return venue.icon.preview
				}else{
					return require('@/assets/images/placeholder.svg')
				}
			};
			
		}
  },
  methods: {
		...mapActions('account', ['getUserInfo']),
		...apiMethods,
    ...venueMethods,
		onEditVenue(venue){


			let payment_type = venue.payg_charged_and_billed_monthly ? 'payg_charged_and_billed_monthly' : 'payg';

			let selected_venue = {
				credits_remaining: venue.active_tokens.length,
				id: venue.id,
				name: venue.name,
				expiry_year: venue.expiry_year,
				expiry_month: venue.expiry_month,
				payment_process: venue.payment_process,
				currency_id: venue.currency_id,
				payment_type: payment_type
			}

			this.$store.dispatch('account/updateVenueInfo', selected_venue)
				.then(() => {
					this.$router.push({ name: "VenuesDetails", params: { venueId: venue.id } });
				});
		},
		addVenue() {
			sessionStorage.setItem('from_add_venue', 'true');
			this.$router.push("/venues/onboard");
		},
		cardExpiryCheck(venue) {

			if (venue.expiry_year && venue.expiry_month) {
				// if venue payment card expires within the next 60 days then show reminder otherwise dont show
				const currentDate = new Date();
				const expiryDate = new Date(parseInt(venue.expiry_year), parseInt(venue.expiry_month), 0);
				const diffInTime = expiryDate.getTime() - currentDate.getTime();				
				const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

				return diffInDays > 0 && diffInDays < 60 ? true : false;

			} else {
				return false;
			}

		},
		cardExpiredCheck(venue) {
			if (venue.expiry_year && venue.expiry_month) {

				const currentDate = new Date();
				const expiryDate = new Date(parseInt(venue.expiry_year), parseInt(venue.expiry_month), 0);
				const diffInTime = expiryDate.getTime() - currentDate.getTime();
				const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

				return diffInDays < 1 ? true : false;

			} else {
				return false;
			}
		},
		calculateSubRenewalDate(index) {

			const renewalDate = new Date(this.venues[index].active_subscription.end_date);
			const today = new Date();
			const timeDifference = renewalDate.getTime() - today.getTime();
			const totalDaysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
			// const remainingMonths = Math.floor(totalDaysDifference / 30);  
			// const remainingDays = totalDaysDifference % 30;  

			return totalDaysDifference == 1 ? `Renewing in ${totalDaysDifference} day` : `Renewing in ${totalDaysDifference} days`;
		}
  },
	async mounted(){

		this.isLoading = true;

		await this.getUserInfo();
		this.acc_info = this.getUserInfo;

		this.getVenuesList().then(response => {
		this.venues = response
		console.log(this.venues);
		}).catch((error) => {
		console.log('Error in getVenuesList', error)
		}).finally(() => {
			this.isLoading = false;
			if (this.venues && this.venues.length === 1 && this.user && this.user.is_opus) {
				this.onEditVenue(this.venues[0].id);
			}
		});
	}
};
</script>
